@import "colors";

// =====================================
// Base Layout Styling
// =====================================

.app-header {
  background-color: #1a1a1a;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  position: sticky;
  padding: 0.5rem;
  top: 0;
  z-index: 10;

  .navbar-nav {
    .nav-item {
      margin-left: 15px;
      .nav-link.active {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          width: 15px;
          height: 3px;
          background-color: #fff;
          bottom: 0;
          left: 50%;
          margin-left: -7px;
          border-radius: 5px;
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.app-footer {
  .upper-area {
    background-image: url("../assets/images/footer_bg.png");
    background-size: cover;
    background-position: 100% 100%;
    padding-top: 3.5em;
    padding-bottom: 2.5em;
    color: #fff;

    .info {
      margin-top: 20px;
      margin-bottom: 0;
      li {
        font-weight: 200;
        padding-left: 25px;
        position: relative;
        margin-bottom: 10px;
        font-size: 13px;

        span {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }

    ul.social-media-links {
      margin-top: 20px;
      color: #fff;
    }

    h3 {
      font-weight: normal;
    }
  }
  .bottom-area {
    background-color: #1a1a1a;
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    color: $light-grey;
  }
}

#scroll-top-button {
  position: fixed;
  width: 40px;
  right: 40px;
  bottom: 40px;
  height: 40px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  transition: all 0.2s;
  border-radius: 10px;

  &:hover {
    box-shadow: 0 0 5px 6px rgba($color: #000000, $alpha: 0.2);
  }
}

.btn.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;

  &:hover,
  &:active {
    background-color: #189fff;
  }
}

#typing-effect {
  width: 22ch;
  // animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  font-family: monospace;
  font-size: 2em;

  span {
    border-right: 0.05em solid;
    animation: blink 1s steps(1) infinite;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.section-margin {
  margin-bottom: 7em !important;
}

// ==================================
// Page Views Styling
// ==================================

.home-container,
.about-us-page {
  section.cover-container {
    background-image: url("../assets/images/header_img.png");
    background-size: 100%;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    height: 100vh;

    main {
      max-width: 50em;
      height: calc(100% - 200px);
      justify-content: center;
      position: relative;
      margin-bottom: 100px;

      .heading {
        color: $primary-color;
        line-height: 1.3;
        font-family: "Oswald", sans-serif;
        font-weight: 500;
        font-size: 3em;
      }

      .lead {
        font-size: 1.5em;
      }

      .scroll-btn {
        position: absolute;
        left: 50%;
        margin-left: -10px;
        bottom: 0;
      }
    }
  }

  section.who-are-we {
    .heading {
      color: $light-grey;
      font-family: "Oswald";
      margin-bottom: 0px;
      font-size: 4.5em;
      font-weight: 500;
    }
    .sub-heading {
      margin-bottom: 20px;
      font-weight: normal;
      font-size: 3.5em;
    }

    .content {
      color: $light-grey;
      font-weight: 300;
      font-size: 19px;
    }

    .img-fluid {
      margin: 30px 0;
    }
  }

  section.services {
    color: #fff;
    padding: 100px 0;

    position: relative;

    &::before {
      content: "";
      width: 100%;
      height: 50%;
      position: absolute;
      background-color: #ececec;
      bottom: 0;
      left: 0;
      z-index: -1;
    }

    .service-bg-img {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .heading {
      font-family: "Oswald";
      font-size: 4.5em;
      position: relative;
      line-height: 1;
      margin-bottom: 80px;

      &::after {
        content: "";
        position: absolute;
        width: 100px;
        height: 7px;
        background-color: #fff;
        bottom: -40px;
        left: 0;
        border-radius: 3px;
      }
    }
    .sub-heading {
      font-weight: 300;
      font-size: 2em;
      line-height: 1.2;
      margin-bottom: 34px;
    }

    .content {
      color: $light-grey;
      font-weight: 300;
      font-size: 19px;
    }

    .btn.contant-us {
      background-color: #fff;
      color: $primary-color;
      &:hover {
        box-shadow: 0 0 5px 6px rgba($color: #000000, $alpha: 0.1);
      }
    }
  }

  section.contact-us {
    position: relative;
    padding-top: 7em;
    padding-bottom: 7em;

    .contact-bg-img {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }

    .container {
      background-color: $dark-grey;
      max-width: 1000px;
    }

    .info {
      padding: 3em;
      color: #fff;
      height: 100%;

      .text {
        font-size: 1.5em;
        margin-top: 20px;
        font-weight: 300;
      }
    }
    .contact-us-form {
      padding: 2.5em;
      background-color: #fff;

      .title {
        font-family: "Oswald";
        font-size: 3em;
        color: #3d3d3d;
      }

      .row.form-group {
        margin-bottom: 15px;
        .form-control {
          background-color: #ececec;
          font-weight: 300;
        }
      }
    }
  }
}

.contact-us-page {
  .info-block {
    .container {
      max-width: 1000px;
    }
    .info-card {
      border-radius: 20px;
      box-shadow: 0 0 5px 2px rgba($color: #000000, $alpha: 0.1);
      padding: 20px;
      margin-bottom: 10px;

      //   .icon {
      //     height: 60px;
      //   }

      .text {
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        margin-top: 20px;
        font-size: 1.5em;
        font-weight: 400;
      }
    }
  }

  .contact-us-form {
    background-color: #ececec;
    background-image: url("../assets/images/contact_form_bg.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right center;
    margin-top: 70px;
    padding: 70px 0;

    .container {
      max-width: 500px;

      .title {
        font-family: "Oswald";
        font-weight: 500;
        margin-bottom: 1em;
        color: #707070;
      }

      .row.form-group {
        margin-bottom: 18px;
        .form-control {
          background-color: #fff;
          font-weight: 300;
          color: #949494;
        }
      }
    }
  }
}

.service-page {
  position: relative;
  background-color: #eeeeee;
  padding-bottom: 5rem;

  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url("./../assets/images/contactBG.png");
  //   z-index: -10;
  //   background-size: contain;
  //   background-color: #ececec;
  // }

  .service-container {
    margin-bottom: 4rem;

    .title {
      font-family: "Oswald";
      font-size: 2.5em;
      margin-bottom: 15px;
      color: #3d3d3d;
    }

    .description {
      font-weight: 300;
      font-size: 1em;
      color: #575757;
    }
  }
}

.about-us-page {
  .meet-our-team {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../assets/images/teamBG.png");
    padding: 70px 0;
    color: #fff;

    .title {
      font-size: 60px;
      font-family: "Oswald";
      margin-bottom: 20px;
    }

    .team-slider {
      margin: 20px 0;
      .member {
        text-align: center;
        padding: 25px;

        h3.name {
          margin: 20px 0 15px 0;
        }
      }
      .slick-arrow {
        &.slick-prev {
          left: -45px;
        }
        &.slick-next {
          right: -45px;
        }
      }
    }
  }
}

// ============================================
// Common Components Styling
// ============================================

.services-card {
  border: 1px solid #fff;
  color: #fff;
  border-radius: 10px;
  background-color: rgba($color: #fff, $alpha: 0.1);

  .services-icon {
    margin-bottom: 20px;
    width: 70px;
    // height={100}
  }

  .title {
    font-size: 30px;
    font-family: "Oswald";
    font-weight: normal;
  }

  p.description {
    color: #fff;
    font-size: 12px;
    font-weight: 200;
    height: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .nav-link {
    padding: 0;
    color: #fff;
  }
}

.card-section {
  position: relative;
  // margin-bottom: 70px;
  z-index: 1;

  .card-bg-img {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .heading {
    font-weight: 200;
    font-size: 40px;
    line-height: 1.1;
    margin-bottom: 30px;
    color: #fff;
  }

  .btn.contant-us {
    background-color: #fff;
    color: $primary-color;

    &:hover {
      box-shadow: 0 0 5px 6px rgba($color: #000000, $alpha: 0.1);
    }
  }
}

.page-cover {
  color: #fff;
  height: 100vh;
  max-height: 550px;
  z-index: 1;
  position: relative;
  margin-bottom: 90px;
  background-size: cover;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-position-x: center;

  &.services {
    background-image: url("../assets/images/services_Image.png");
  }
  &.contact-us {
    background-image: url("../assets/images/contact_Image.png");
  }
  &.about-us {
    background-image: url("../assets/images/about.png");
  }

  .page-cover-bg-img {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  main {
    max-width: 42em;
    height: calc(100% - 200px);
    justify-content: center;

    .heading {
      color: $primary-color;
      line-height: 1.3;
      font-family: "Oswald";
      font-weight: 500;
      font-size: 4em;
    }
  }
}

@media screen and (max-width: 765px) {
  .section-margin {
    margin: 0 !important;
  }

  .app-header {
    background-color: #1a1a1a;

    .navbar-nav {
      .nav-item {
        margin-left: 0;
        .nav-link.active {
          &::after {
            content: none;
          }
        }
      }
    }
  }

  .app-footer {
    text-align: center;
    .upper-area {
      .info {
        margin-top: 30px;
        margin-bottom: 30px;
        li {
          span {
            top: 0;
            left: 50%;
            margin-left: -90px;
          }
        }
      }
    }
  }

  .card-section {
    background-color: $primary-color;
    .card-bg-img {
      display: none;
    }

    .row {
      flex-direction: column-reverse;

      .col-md-8 {
        text-align: center;
        .heading {
          margin-top: 30px;
        }
      }
    }
  }

  section.who-are-we {
  }

  .btn.contant-us {
    margin-bottom: 30px;
  }

  .home-container {
    section.cover-container {
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      color: #fff;
      height: 400px;

      main {
        height: calc(100% - 90px);

        .heading {
          font-size: 2em;
        }

        #typing-effect {
          font-size: 1.4em;
        }

        .scroll-btn {
          display: none;
        }
      }
    }

    section.contact-us {
      background-color: #ececec;
      padding: 0 !important;

      .contact-bg-img {
        display: none;
      }

      .contact-us-form {
        padding: 40px 20px !important;
      }
    }

    section.services {
      padding: 40px 0 !important;
      background-color: $primary-color;

      &::before {
        content: none;
      }

      .service-bg-img {
        display: none;
      }
    }
  }

  .page-cover {
    color: #fff;
    height: 400px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    margin-bottom: 50px;

    main {
      height: calc(100% - 170px);
      .heading {
        font-size: 3em !important;
        margin-bottom: 0;
      }
    }
  }

  .service-page {
    position: relative;
    margin-bottom: 0;
    padding: 0;

    .service-container {
      margin-bottom: 5em;

      .title {
        font-family: "Oswald";
        font-size: 40px;
        margin-bottom: 15px;
      }

      .description {
        font-weight: 300;
        font-size: 18px;
        color: #777;
      }
    }
  }
}
